<template>
  <button v-if="isUserLoggedIn" @click="profile" class="d-flex align-center justify-end px-2">
    <img :src="userdata.image" alt="" v-if="userdata.image" class="companyimage">
    <v-icon icon="mdi-account" v-else></v-icon>
    <div>
      <p class="px-1 font-weight-bold text-right">
      {{ $t("Profile") }}
      </p>

    <h5 class="text-center name_user">
      {{ userdata.name }}
    </h5>
      <p class="afaz_auth" v-if="userdata.is_nafath_verified"  >
        <svg aria-hidden="true" data-prefix="fas" data-icon="badge-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"  width="18" height="18" class="svg-inline--fa fa-badge-check fa-w-16 fa-7x">
          <path fill="#2ea156" d="M512 256c0-37.7-23.7-69.9-57.1-82.4 14.7-32.4 8.8-71.9-17.9-98.6-26.7-26.7-66.2-32.6-98.6-17.9C325.9 23.7 293.7 0 256 0s-69.9 23.7-82.4 57.1c-32.4-14.7-72-8.8-98.6 17.9-26.7 26.7-32.6 66.2-17.9 98.6C23.7 186.1 0 218.3 0 256s23.7 69.9 57.1 82.4c-14.7 32.4-8.8 72 17.9 98.6 26.6 26.6 66.1 32.7 98.6 17.9 12.5 33.3 44.7 57.1 82.4 57.1s69.9-23.7 82.4-57.1c32.6 14.8 72 8.7 98.6-17.9 26.7-26.7 32.6-66.2 17.9-98.6 33.4-12.5 57.1-44.7 57.1-82.4zm-144.8-44.25L236.16 341.74c-4.31 4.28-11.28 4.25-15.55-.06l-75.72-76.33c-4.28-4.31-4.25-11.28.06-15.56l26.03-25.82c4.31-4.28 11.28-4.25 15.56.06l42.15 42.49 97.2-96.42c4.31-4.28 11.28-4.25 15.55.06l25.82 26.03c4.28 4.32 4.26 11.29-.06 15.56z"></path>
        </svg>
        {{ $t("afaz_auth") }}
      </p>
    </div>
    
  </button>
  <button class="px-2 d-flex align-center justify-end" @click="resigt" v-else>
    <v-icon icon="mdi-account-outline"></v-icon>
    <p>
    {{ $t("LogIn") }}
    </p>
  </button>
</template>
<script>
import { ref } from "vue";
import { useAuth } from "../../axios";
import crudDataService from "@/Services/crudDataService";
export default {
  data(){
    return{
      logined:"",
      userdata:''
    }
  },
  setup() {
    const { isLoggedIn } = useAuth();
    const isUserLoggedIn = ref(isLoggedIn());
    return {
      isUserLoggedIn,
    };
  },
  components: {},
  methods: {
    async userprofile() {
      const id = localStorage.getItem("userId");
      if (id) {
      let response = await crudDataService.get(`user-profile`, id);
      this.userdata=response.data.data
      // console.log(this.userdata);
      }
    },
    resigt() {
      this.$router.push({ name: "LogIn" });
    },
    profile() {
      this.$router.push({ name: "Profile" });
    },
  },
  mounted() {
    this.userprofile()
    // this.logined = localStorage.getItem("authToken")?true:false
    // // console.log(this.logined);
  },
};
</script>
<style lang="scss" scoped>
.companyimage{
  width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #000;
}
.afaz_auth{
  color: #2ea156;
  display: flex;
  align-items: center; /* يجعل المحتوى في المنتصف عموديًا */
  gap: 8px; /* مسافة بين الأيقونة والنص */
}

.name_user{
  font-size: 10px;
}
.afaz_auth svg {
  flex-shrink: 0; /* يمنع تصغير الأيقونة عند ضغط المساحة */
}
</style>
