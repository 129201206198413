<template>
  <v-container>
      <v-row>
        <v-col cols="12" md="3"
        v-for="(item, index) in items"
        :key="index">
          <v-list-item
        class="list_profile py-4 mb-3"
        
        @click="navigateTo(item.route)"
      >
        <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
      </v-list-item>
        </v-col>
      </v-row>
     
      <button @click="logout"  class="logout py-4 mb-3">
        {{ $t('logout') }}
      </button>
  </v-container>
</template>
<script>
import crudDataService from "@/Services/crudDataService";
import { ref } from 'vue';
import { useAuth } from "../axios";
export default {
  components: {},
  data() {
    return {
      items: [
        { text: "chat", route: "/chats" },
        { text: "Favorite_ads", route: "/favorite-ads" },
        { text: "Followers", route: "/followers" },
        { text: "Account_settings", route: "/account-settings" },
        { text: "Subscriptions", route: "/subscriptions" },
        { text: "My_ads", route: "/my-ads" },
        { text: "Commission", route: "/commission" },
        { text: "Blocked_Persons", route: "/blocked-persons" },
        { text: "Site_balance", route: "/site-balance" },
        { text: "About", route: "/about-us" },
        { text: "Terms", route: "/terms" },
        { text: "Adsconditions", route: "/ads-conditions" },
        { text: "privacy", route: "/privacy" },
        { text: "Agreement", route: "/agreement" },
        { text: "idea_policy", route: "/idea-Policy" },
      ],
    };
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
   async logout(){
    try {
      let response=  await crudDataService.getAll("logout")
      this.$router.push({ name: 'home' })
      .then(() => {
        const {clearToken} = useAuth();
        clearToken()
        window.location.reload();
    });
  }catch(error)  {
          // console.log(error.request);
          }
   
  
    
    }
    
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.list_profile:first-child {
  // border: 1px solid #000;

}
.list_profile {
  border: 2px solid #f6c712;
  border-radius: 5px;
  .v-list-item-title{
    font-size: 22px;
  font-weight: 500;
  line-height: normal;
  }
  
}
.logout{
  padding: 15px;
  text-align: start;
  width: 100%;
  border: 2px solid #DF0000;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
}
</style>
