<template>
  <section>
    <h2 class="text-center mb-2">
      {{ $t("Add_new_ad") }}
    </h2>
    <label class="font-weight-bold">
      {{ $t("main_type") }}
    </label>
    <Multiselect
      :options="optionsmaintype"
      :searchable="true"
      :placeholder="$t('main_type')"
      label="name"
      class="selectnew"
      v-model="formData.main_type"
    />
    <label class="font-weight-bold">
      {{ $t("City") }}
    </label>
    <multiselect
      :options="optionscity"
      :searchable="true"
      :selected="null"
      :placeholder="$t('City')"
      label="name"
      class="selectnew"
      v-model="formData.city_id"
      @change="citychange"
    ></multiselect>
    <label class="font-weight-bold">
      {{ $t("neighborhood") }}
    </label>
    <multiselect
      :options="optionsneighborhood"
      :searchable="true"
      :placeholder="$t('neighborhood')"
      label="name"
      class="selectnew"
      v-model="formData.neighborhood_id"
    ></multiselect>
    <label class="font-weight-bold">
      {{ $t("Area") }}
    </label>
    <Multiselect
      :options="optionsarea"
      :searchable="true"
      :placeholder="$t('Area')"
      label="name"
      class="selectnew"
      v-model="formData.area_id"
    />
    <label class="font-weight-bold">
      {{ $t("estate_type") }}
    </label>
    <Multiselect
      :options="optionsestate"
      :searchable="true"
      :placeholder="$t('estate_type')"
      label="name"
      class="selectnew"
      v-model="formData.estate_type_id"
      @change="estate($event)"
    />
    <label class="font-weight-bold">
      {{ $t("ads_type") }}
    </label>
    <Multiselect
      :options="optionsadtype"
      :searchable="true"
      :placeholder="$t('ads_type')"
      label="name"
      class="selectnew"
      v-model="formData.ad_type_id"

    />
    <label class="font-weight-bold">
      {{ $t("usage_type") }}
    </label>
    <Multiselect
      :options="optionsusagetype"
      :searchable="true"
      :placeholder="$t('usage_type')"
      label="name"
      class="selectnew"
      v-model="formData.usage_type_id"
      @change="usage($event)"

    />
    <label class="font-weight-bold">
      {{ $t("description") }}
    </label>
    <v-text-field
      variant="solo"
      class="selectnew"
      v-model="formData.description"
    ></v-text-field>
    <label class="font-weight-bold">
      {{ $t("address") }}
    </label>
    <v-text-field
      variant="solo"
      class="selectnew"
      v-model="formData.address"
    ></v-text-field>
    <label class="font-weight-bold">
      {{ $t("Location_Details") }}
    </label>
    <v-text-field
      variant="solo"
      class="selectnew"
      v-model="formData.location"
    ></v-text-field>
    <label class="font-weight-bold">
      {{ $t("lat") }}
    </label>
    <v-text-field
      variant="solo"
      class="selectnew"
      v-model="formData.lat"
    ></v-text-field>
    <label class="font-weight-bold">
      {{ $t("lang") }}
    </label>
    <v-text-field
      variant="solo"
      class="selectnew"
      v-model="formData.lng"
    ></v-text-field>
    <label class="font-weight-bold">
      {{ $t("price") }}
    </label>
    <v-text-field
      type="number"
      variant="solo"
      class="selectnew"
      v-model="formData.price"
    ></v-text-field>
    <v-row>
      <v-col cols="12" sm="6">
        <label class="font-weight-bold">
          {{ $t("from") }}
        </label>
        <v-text-field
          type="number"
          variant="solo"
          class="selectnew"
          v-model="formData.max_price"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <label class="font-weight-bold">
          {{ $t("to") }}
        </label>
        <v-text-field
          type="number"
          variant="solo"
          class="selectnew"
          v-model="formData.min_price"
        ></v-text-field>
      </v-col>
    </v-row>

    <label class="font-weight-bold">
      {{ $t("Aarea") }}
    </label>
    <v-text-field
      type="number"
      variant="solo"
      class="selectnew"
      v-model="formData.area"
    ></v-text-field>
    <v-row>
      <v-col cols="12" sm="6">
        <label class="font-weight-bold">
          {{ $t("from") }}
        </label>
        <v-text-field
          type="number"
          variant="solo"
          class="selectnew"
          v-model="formData.max_area"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <label class="font-weight-bold">
          {{ $t("to") }}
        </label>
        <v-text-field
          type="number"
          variant="solo"
          class="selectnew"
          v-model="formData.min_area"
        ></v-text-field>
      </v-col>
    </v-row>

    <label class="font-weight-bold">
      {{ $t("mortgage") }}
    </label>
    <v-text-field
      type="number"
      variant="solo"
      class="selectnew"
      v-model="formData.mortgage"
    ></v-text-field>
    <h3>
      {{ $t('When_adding_real')}}
    </h3>
    <label class="font-weight-bold">
      {{ $t("advertiser_orientation") }}
    </label>
    <Multiselect
      :options="optionsadvertiserorientation"
      :searchable="true"
      :placeholder="$t('advertiser_orientation')"
      label="name"
      class="selectnew"
      v-model="formData.advertiser_orientation_id"
    />
    <label class="font-weight-bold">
      {{ $t("advertiser_type") }}
    </label>
    <Multiselect
      :options="optionadvertisertype"
      :searchable="true"
      :placeholder="$t('advertiser_type')"
      label="name"
      class="selectnew"
      v-model="formData.advertiser_type"
    />
    <label class="font-weight-bold">
      {{ $t("license_number") }}
    </label>
    <v-text-field
      type="number"
      variant="solo"
      class="selectnew"
      v-model="formData.license_number"
    ></v-text-field>
    <label class="font-weight-bold">
      {{ $t("delegation_number") }}
    </label>
    <v-text-field
      type="number"
      variant="solo"
      class="selectnew"
      v-model="formData.delegation_number"
    ></v-text-field>
    <label class="font-weight-bold">
      {{ $t("advertiser_registration_number") }}
    </label>
    <v-text-field
      type="number"
      variant="solo"
      class="selectnew"
      v-model="formData.advertiser_registration_number"
    ></v-text-field>
    <v-checkbox
      :label="$t('special')"
      class="check"
      @change="checkspcial"
    ></v-checkbox>
    <v-checkbox
      label="Story"
      class="check"
      @change="checked"
    ></v-checkbox>
    <label class="font-weight-bold">
      {{ $t("image") }}
    </label>
    <v-file-input
      variant="solo"
      class="selectnew my-2"
      v-model="formData.attachment"
      label="Add Image"
      prepend-icon="mdi-camera"
      accept=".pdf, image/jpeg, image/png"
      @change="handleFileChange"
    ></v-file-input>
    <v-row class="mt-2">
      <v-col cols="12" md="3" v-for="item in optionsAdd" :key="item.id">
        <label class="font-weight-bold">
          {{ $t(item.name) }}
        </label>
        <Multiselect
          :options="optionslid"
          :searchable="true"
          :placeholder="$t(item.name)"
          label="name"
          class="selectnew"
          @change="prepareAndAddData(item.id, $event, item.name)"
        />
      </v-col>
    </v-row>
    <div class="d-flex align-center">
      <v-checkbox
        class="check d-flex flex-0-0"
        @change="check"
      ></v-checkbox>
      <label @click="dialog = true">
        <h3 style="cursor: pointer">
          {{ $t("Conditions_advertisement") }}
        </h3>
      </label>
    </div>
    <div class="text-red">{{ errorMessage ? errorMessage : "" }}</div>

    <div class="text-center">
      <button class="previewads w-50 pa-2 mt-5" @click="addnewad">
        {{ $t("Add_new_ad") }}
      </button>
    </div>
    <v-dialog v-model="dialog" width="700">
      <v-card class="text-center py-5">
        <h3
          class="w-max-content mx-auto rounded pa-1 text-h5 font-weight-bold bg-yellow-darken-1"
        >
          {{ $t("Conditions_advertisement") }}
        </h3>
        <p class="pa-5 text-h6">
        ##
        </p>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import crudDataService from "@/Services/crudDataService";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      errorMessage: "",
      eventchange: "",
      optionslid: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
      optionsAdd: [
        {
          id: 1,
          name: "rooms",
        },
        {
          id: 2,
          name: "Hall",
        },
        {
          id: 3,
          name: "Bathrooms",
        },
        {
          id: 4,
          name: "kitchen",
        },
        {
          id: 5,
          name: "Street_width",
        },
        {
          id: 6,
          name: "Age_property",
        },
      ],
      dialog: false,
      optionscity: [],
      optionsarea: [],
      optionsneighborhood: [],
      optionsestate: [],
      optionsadtype: [],
      optionsusagetype: [],
      optionsmaintype: [],
      optionsadvertiserorientation: [],
      optionadvertisertype: [],
      nei_id: "",
      formData: {
        city_id: "",
        neighborhood_id: "",
        area_id: "",
        estate_type_id: "",
        ad_type_id: "",
        usage_type_id: "",
        main_type: "",
        description: "",
        address: "",
        location: "",
        lng: "",
        lat: "",
        price: "",
        min_price: "",
        max_price: "",
        area: "",
        min_area: "",
        max_area: "",
        mortgage: "",
        estate_notes: 0,
        advertiser_orientation_id: "",
        advertiser_type: "",
        license_number: "",
        delegation_number: "",
        advertiser_registration_number: "",
        attachment: [],
        is_story: 0,
        special:0,
        options: [],
        usage_id:'',
        estate_id:''
      },
    };
  },
  methods: {
    handleFileChange(event) {
      this.formData.attachment[0] = event.target.files.item(0);
    },
    checked(e) {
      if (e.target.checked) {
        this.formData.is_story=1
      }else{
        this.formData.is_story=0
      }
    },
    check(e) {
      if (e.target.checked) {
        this.formData.estate_notes=1
      }else{
        this.formData.estate_notes=0
      }
    },
    checkspcial(e){
      if (e.target.checked) {
        this.formData.special=1
      }else{
        this.formData.special=0
      }
    },
    async Areas() {
      let response = await crudDataService.getAll(`areas`);
      this.optionsarea = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    async City() {
      let response = await crudDataService.getAll(`cities`);
      this.optionscity = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    citychange(e) {
      this.nei_id = e;
      this.neighborhood_id();
    },
    async neighborhood_id() {
      let response = await crudDataService.getAll(
        `neighborhoods?city_id=${this.nei_id}`
      );
      this.optionsneighborhood = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
      // console.log(response.data.data);
    },
    async estate_types() {
      let response = await crudDataService.getAll(`estate-types`);
      this.optionsestate = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    async ad_types() {
      let response = await crudDataService.getAll(`ad-types`);
      this.optionsadtype = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    async usage_types() {
      let response = await crudDataService.getAll(`usage-types`);
      this.optionsusagetype = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    async main_type() {
      this.optionsmaintype = [
        { value: "sell", name: this.$t("Real_offers") },
        { value: "buy", name: this.$t("Real_requests") },
      ];
    },
    async advertiser_orientation() {
      let response = await crudDataService.getAll(`advertiserOrientation`);
      this.optionsadvertiserorientation = response.data.data.map((state) => ({
        value: state.id,
        name: state.name,
      }));
    },
    async advertiser_type() {
      this.optionadvertisertype = [
        { value: "owner", name: this.$t("owner") },
        { value: "delegate", name: this.$t("delegate") },
      ];
    },
    prepareAndAddData(id, event, name) {
      this.formData.options.push({
        property_id: id,
        name: name,
        values: event,
      });
    },
    estate(e){
     this.estate_id=e
     // console.log(e);
    },
    usage(e){
     this.usage_id=e;
     // console.log(e);
    this.properties()
    },
    async properties(){
      let response = await crudDataService.getAll(`properties/${this.estate_id}/${this.usage_id}`);
      // console.log(response)
    },
    async addnewad() {
      let response = await crudDataService
        .create(`store-ad`, this.formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {})
        .catch((error) => {
          if (error.response) {
            // The API responded with an error message
            this.errorMessage = error.response.data.message;
          } else if (error.request) {
            this.errorMessage = "No response from the server";
          } else {
            this.errorMessage = error.message;
          }
        });
    },
  },
  mounted() {
    this.Areas();
    this.City();
    this.estate_types();
    this.ad_types();
    this.usage_types();
    this.main_type();
    this.advertiser_orientation();
    this.advertiser_type();
  },
};
</script>

<style lang="scss">
.selectnew {
  &.multiselect {
    margin-top: 8px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .multiselect-search {
    border-radius: 10px;
  }
}
.selectnew.v-input--density-default .v-field--variant-outlined,
.selectnew.v-input--density-default .v-field--single-line,
.selectnew.v-input--density-default .v-field--no-label {
  box-shadow: none !important;
  border: 1px solid #d1d5db !important;
  border-radius: 10px !important;
  margin-top: 6px;
  margin-bottom: 8px;
}
.check .v-input__details,
.selectnew .v-input__details {
  display: none !important;
}
.selectnew.v-text-field input.v-field__input {
  min-height: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}
</style>
