<template>
  <v-container>
    <section class="border rounded">
      <l-map
        :zoom="zoomout"
        :key="mapKey"
        :options="mapOptions"
        :center="initialCoordinates"
        height="350px!important"
        @click="get"
      >
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        ></l-tile-layer>

        <!-- <l-marker 
        v-for="(marker,i) in markers" 
        :key="i" 
        :lat-lng="marker.latLng"
        @click="single(marker.id)"
      > -->
        <l-marker
          v-for="(marker, i) in markers"
          :key="i"
          :lat-lng="marker.latLng"
          @click="filter(marker.id,marker.RealLatLng)"
        >
          <l-icon :icon-anchor="staticAnchor" class-name="someExtraClass">
            <div class="headline">
              {{ marker.name }}
            </div>
          </l-icon>
        </l-marker>

        <!-- <l-tooltip  >
          {{ marker.price }}
          </l-tooltip> -->

        <!-- </l-marker> -->
        <!-- <l-marker :lat-lng="markerCoordinates"></l-marker>
  
      <l-marker
   key="1"
   :lat-lng="markerCoordinates"
  >
  </l-marker> -->
      </l-map>
    </section>
  </v-container>
</template>

<script>
import crudDataService from "@/Services/crudDataService";

import "leaflet/dist/leaflet.css";
import { latLng, icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LIcon,
} from "@vue-leaflet/vue-leaflet";
export default {
  data() {
    return {
      markers: [],
      mapOptions: {
        zoomControl: false,
        scrollWheelZoom: false,
        touchZoom: false,
        doubleClickZoom: false,
        boxZoom: false,
      },
      zoomout: 6,
      mapKey: 0,
      icon: icon({
        iconUrl: "static/images/baseball-marker.png",
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
      staticAnchor: [16, 37],
      initialCoordinates: [24.774265, 46.738586],
      markerCoordinates: [24.774265, 46.738586],
    };
  },
  components: {
    LIcon,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  setup() {},
  methods: {
    filter(id,RealLatLng) {
      this.$router.push({name:"AllINCity", params: { param1: id  },query: { RealLatLng: RealLatLng },})
    },
    single(id) {
      this.$router.push({ name: "SingleAd", params: { id } });
    },
    async getallmark() {
      let res = await crudDataService.getAll("states");
      // Add markers from the 'nearest' array
      // console.log(res.data.data);
      res.data.data.forEach((element) => {
        // // console.log(element); // Logging each element
        this.markers.push({
          latLng: [element.lat, element.lng],
          RealLatLng: [element.real_lat, element.real_lng],
          name: element.name,
          id: element.id,
        });
      });

      // Add markers from the 'latest' array
    },
    updateMarkerCoordinates() {
      // // console.log(this.markerCoordinates);

      const route = this.$route;
      if (route.params.param1 && route.params.param2) {

        let lat = parseFloat(route.params.param1);
        let lng = parseFloat(route.params.param2);
        this.markerCoordinates = [lat, lng];
        this.initialCoordinates = [lat, lng];
        this.zoomout = 14;
        this.mapKey++;

      } else {
        // console.log("No coordinates provided");

        //   // console.log('ihwdwdihwoiowqjjo');
        //   // console.log(route.params.param1 );
        //   this.markerCoordinates = [
        //  +route.params.param1
        //     ,
        //   +route.params.param2
        //   ]
        //   // console.log(this.markerCoordinates);
      }
    },
    get() {
      // console.log(LMarker.props.latLng);
    },
  },
  mounted() {
    this.updateMarkerCoordinates();
    this.getallmark();
  },
};
</script>
<style lang="scss">

.leaflet-container {
    height: 115vh !important;
}
.leaflet-marker-icon.someExtraClass.leaflet-zoom-animated.leaflet-interactive {
  width: 120px !important;
  text-align: center;
}
.headline {
  background-color: #fad76d !important;
  border: 1px solid #000 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  opacity: 1 !important;
  width: 100%;
  border-radius: 20px;
  padding: 10px;
  font-size: 18px;
}
.headline:hover {
  background-color: #fff !important;
  color: #e6ac00 !important;
  border: 1px solid #e6ac00 !important;
  
}
.leaflet-tooltip {
  background-color: #fad76d !important;
  border: 1px solid #000 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  opacity: 1 !important;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before,
.leaflet-tooltip-top:before {
  border: 0 solid transparent !important;
  border: none !important;
}
.border{
height: 800px !important;
    overflow: hidden;
// width: 600px !important;
// margin: auto;
}

.v-input.v-input--horizontal.border {
  height: auto !important;
}
</style>
