import axios from "axios";
import router from "./router";
const lang=localStorage.getItem('lang');
console.log(lang);
const http = axios.create({
  baseURL: axios.defaults.baseURL,
  headers: {
    "Content-type":  "application/json",
    Accept: "application/json",
    Authorization: axios.defaults.token,
    // "Accept-Language":localStorage.getItem('lang')??"ar",
  },
});
// http.interceptors.response.use(
  // (res) => {
    // return res;
  // },
  // (error) => {
    // if (!navigator.onLine) {
    //   toast.error("No internet connection, please check your connection.", {
    //     position: "top-center",
    //   });

      // console.clear();
    // }

    // Do something with response error
    // if (error && error.response) {
    //   if (
    //     error.response.status == 422 ||
    //     error.response.status == 500 ||
    //     error.response.status == 419
    //   ) {
    //     let hide = document.querySelector("#loaders4");
    //     if (error.response.data.message.length > 0) {
    //       hide.style.display = "none";
    //     } else {
    //       for (const [key, value] of Object.entries(
    //         error.response.data.message
    //       )) {
    //       }
    //       hide.style.display = "none";
    //     }
    //     hide.style.display = "none";
    //   } else if (error.response.status == 401) {
    //   } else if (error.response.status == 404) {
    //   } else if (
    //     error.response.status === 401 ||
    //     !localStorage.getItem("loggedIn")
    //   ) {
    //     // localStorage.clear();
    //     // if (window.location.href.search("login") == -1) {
    //     //   router.replace("/login");
    //     // }
    //   } else {
    //     return Promise.reject(error.response);
    //   }
    // }
  // }
// );
http.interceptors.request.use(
  (config) => {
    // Check if the token exists
    const token = axios.defaults.token;
    if (token) {
      // Add the token to the request header
      config.headers["Authorization"] = `${token}`;
    }
    config.headers["Accept-Language"]=localStorage.getItem('lang')??"ar"
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
